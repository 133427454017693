const config = {
  "apigateway_key": "YnFEWnVhRWZTajU2YXJtU2xSbk1lNU8yNw==",
  "version": "1.0.202211180840",
  "application_name": "Seacom",
  "serverUrl": "https://seacom.xeverix.com/",
  "menuExclusionsList": [
    "/authentication/login"
    , "/authentication/reset-password"
  ],
  "authentication": {
    "defaultUserRegistrationGroup": "1"
  },
  "refresh_interval": 30000
}

export { config }
//http://13.245.156.220:8082
//"serverUrl": "http://13.245.156.220:8094",